<template>
  <v-dialog v-model="visivel" max-width="800" persistent>
    <v-form ref="form" @submit.prevent="salvarTitulo">
      <v-card v-if="formulario">
        <v-card-title>
          <span class="headline">Editar títulos</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="3">
              <v-text-field
                v-model="formulario.adquirente"
                label="Adquirente"
                :rules="[obrigatorio]"
                disabled
              />
            </v-col>
            <v-col sm="3">
              <v-text-field
                ref="campoCodigoAutorizacaoAdquirente"
                v-model="formulario.codigoAutorizacaoAdquirente"
                label="Código Autorização"
                :rules="[obrigatorio]"
              />
            </v-col>
            <v-col sm="3">
              <v-text-field
                v-model="formulario.empresa.nome"
                label="Empresa"
                :rules="[obrigatorio]"
                disabled
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="formulario.dataEmissaoTitulo"
                label="Data Emissão"
                :rules="[obrigatorio]"
                disabled
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="formulario.dataVencimentoTitulo"
                label="Data Vencimento"
                :rules="[obrigatorio]"
                disabled
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="formulario.codigoTitulo"
                label="Código Título"
                :rules="[obrigatorio]"
                disabled
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="formulario.parcelaTitulo"
                label=" Parcela Título"
                :rules="[obrigatorio]"
                disabled
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="formulario.valorTitulo"
                label="Valor Título"
                :rules="[obrigatorio]"
                disabled
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="cancelarTitulo()">Cancelar</v-btn>
          <v-btn color="green darken-1" text :loading="salvando" type="submit"> Salvar </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script lang="ts">
import { obrigatorio } from '@/shareds/regrasForm'
import TituloService from '@/services/titulo/titulo'
import Snackbar from '@/models/ui/snackbar'

export default {
  props: {
    snackbar: Snackbar
  },
  data: () => ({
    visivel: false,
    salvando: false,
    formulario: {
      id: '',
      adquirente: '',
      documentoAdquirente: '',
      codigoTitulo: '',
      codigoTipoTitulo: '',
      codigoFormaPagamento: '',
      parcelaTitulo: '',
      valorTitulo: null,
      dataVencimentoTitulo: '',
      dataEmissaoTitulo: '',
      codigoAutorizacaoAdquirente: '',
      empresa: {
        nome: ''
      }
    },
    obrigatorio: obrigatorio
  }),

  methods: {
    abrir(titulo) {
      this.formulario = titulo
      this.visivel = true
      setTimeout(() => this.$refs.campoCodigoAutorizacaoAdquirente.focus())
    },

    alertError(error, defaultErrorMessage) {
      this.$props.snackbar.snackTextParam = `${error.response.status} | ${
        error.response.data.message === undefined
          ? error.message
          : error.response.data.message
          ? error.response.data.message
          : defaultErrorMessage
      }`
      this.$props.snackbar.snackTypeParam = 'error'
      this.$props.snackbar.showSnackParam = true
    },

    alertSuccess(message) {
      this.$props.snackbar.snackTextParam = message
      this.$props.snackbar.snackTypeParam = 'sucess'
      this.$props.snackbar.showSnackParam = true
    },

    async salvarTitulo() {
      try {
        if (!this.formulario) return
        this.salvando = true

        await TituloService.editarTitulo(this.formulario.id, this.formulario)

        this.$emit('salvar', this.formulario)
        this.alertSuccess('Título editado com sucesso')
        this.salvando = false
        this.visivel = false
      } catch (error) {
        this.alertError(error)
        this.salvando = false
        this.visivel = false
      }
    },

    cancelarTitulo() {
      this.visivel = false
    }
  }
}
</script>
