<template>
  <v-dialog v-model="visisvel" persistent max-width="500">
    <v-card>
      <v-card-title class="headline">{{ titulo }}</v-card-title>
      <v-card-text>{{ mensagem }}</v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="red darken-1" text @click="esconder()"> Cancelar </v-btn>
        <v-btn color="green darken-1" text :loading="carregando" @click="() => onConfirmar()"
          >Confirmar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    titulo: {
      type: String,
      required: true
    }
  },

  data: () => ({
    visisvel: false,
    carregando: false,
    onConfirmarCallback: null,
    mensagem: ''
  }),

  methods: {
    perguntar({ onConfirmarCallback, mensagem }) {
      this.visisvel = true
      this.mensagem = mensagem
      this.onConfirmarCallback = onConfirmarCallback
    },
    esconder() {
      this.visisvel = false
    },
    onConfirmar() {
      this.onConfirmarCallback()
      this.visisvel = false
    }
  }
}
</script>
