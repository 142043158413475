<template>
  <v-container fluid>
    <v-card>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="d-flex align-center">
              <v-icon left> mdi-filter-variant </v-icon>
              Filtros
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-form @submit.prevent="aplicarFiltrosTitulos">
              <v-row>
                <v-col sm="6" md="2">
                  <v-select
                    v-model="filtro.adquirente"
                    :items="itensAdquirente"
                    label="Adquirente *"
                    @change="changeValueAdquirente(filtro.adquirente)"
                  />
                </v-col>

                <v-col sm="6" md="3">
                  <v-text-field
                    v-model="filtro.codigoAutorizacaoAdquirente"
                    label="Código Autorização"
                  />
                </v-col>

                <v-col sm="12" md="7">
                  <v-select
                    v-model="filtro.empresasSelecionadas"
                    label="Empresa"
                    :loading="buscando"
                    :items="itensEmpresa"
                    multiple
                    clearable
                    prepend-icon="mdi-domain"
                  >
                    <template #prepend-item>
                      <v-list-item ripple @click="alterarSelecaoEmpresas">
                        <v-list-item-action>
                          <v-checkbox v-model="todasEmpresasSelecionadas" />
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Selecionar Tudo </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>

                    <template #selection="{ item, index }">
                      <span v-if="index === 3" class="grey--text text-caption">
                        (+{{ filtro.empresasSelecionadas.length - 3 }} outras)
                      </span>

                      <span
                        v-else-if="
                          (index < 2 && index === filtro.empresasSelecionadas.length - 1) ||
                          index == 2
                        "
                        class="mr-1"
                      >
                        {{ `${item.text}` }}
                      </span>

                      <span v-else-if="index < 2" class="mr-1"> {{ `${item.text},` }} </span>
                    </template>
                  </v-select>
                </v-col>

                <v-col sm="6" md="3">
                  <v-text-field
                    v-model="filtro.codigoTitulo"
                    prepend-icon="mdi-numeric"
                    label="Código Título"
                  />
                </v-col>

                <v-col sm="6" md="3">
                  <v-text-field
                    v-model="filtro.valorTitulo"
                    prepend-icon="mdi-currency-usd"
                    label="Valor Título"
                    hint="Use '>' ou '<' para valores maiores/menores. (Ex: >149.99)"
                    autocomplete="off"
                  />
                </v-col>

                <v-col sm="6" md="3">
                  <v-menu
                    ref="menuDataVencimentoInicial"
                    v-model="menuDataVencimentoInicial"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="dataVencimentoInicialFormatada"
                        label="Data Inicial Vencimento"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        :loading="buscando"
                        clearable
                        @click:clear="filtroDataVencimentoInicial = ''"
                        v-on="on"
                      />
                    </template>

                    <v-date-picker
                      v-model="filtroDataVencimentoInicial"
                      color="primary"
                      no-title
                      @input="menuDataVencimentoInicial = false"
                    />
                  </v-menu>
                </v-col>

                <v-col sm="6" md="3">
                  <v-menu
                    ref="menuDataVencimentoFinal"
                    v-model="menuDataVencimentoFinal"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="dataVencimentoFinalFormatada"
                        label="Data Final Vencimento"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        :loading="buscando"
                        clearable
                        @click:clear="filtroDataVencimentoFinal = ''"
                        v-on="on"
                      />
                    </template>

                    <v-date-picker
                      v-model="filtroDataVencimentoFinal"
                      color="primary"
                      no-title
                      @input="menuDataVencimentoFinal = false"
                    />
                  </v-menu>
                </v-col>

                <v-col sm="6" md="3">
                  <v-menu
                    ref="menuDataEmissaoInicial"
                    v-model="menuDataEmissaoInicial"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="dataEmissaoInicialFormatada"
                        label="Data Inicial Emissão"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        :loading="buscando"
                        clearable
                        @click:clear="filtroDataEmissaoInicial = ''"
                        v-on="on"
                      />
                    </template>

                    <v-date-picker
                      v-model="filtroDataEmissaoInicial"
                      color="primary"
                      no-title
                      @input="menuDataEmissaoInicial = false"
                    />
                  </v-menu>
                </v-col>

                <v-col sm="6" md="3">
                  <v-menu
                    ref="menuDataEmissaoFinal"
                    v-model="menuDataEmissaoFinal"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="dataEmissaoFinalFormatada"
                        label="Data Final Emissão"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        :loading="buscando"
                        clearable
                        @click:clear="filtroDataEmissaoFinal = ''"
                        v-on="on"
                      />
                    </template>

                    <v-date-picker
                      v-model="filtroDataEmissaoFinal"
                      color="primary"
                      no-title
                      @input="menuDataEmissaoFinal = false"
                    />
                  </v-menu>
                </v-col>
                <v-col sm="6" md="2">
                  <v-select
                    v-model="filtro.selecaoTitulo"
                    :items="selecaoTitulo"
                    label="Títulos *"
                  />
                </v-col>
              </v-row>

              <v-row justify="end">
                <v-col cols="auto">
                  <v-btn class="ml-4" color="primary" outlined type="submit" :loading="buscando">
                    <v-icon left> mdi-magnify </v-icon>
                    Aplicar
                  </v-btn>
                  <v-btn
                    class="ml-4"
                    color="normal"
                    outlined
                    type="submit"
                    :loading="buscando"
                    @click.stop.prevent="limparSelecaoDeFiltros"
                  >
                    <v-icon left> mdi-autorenew </v-icon>
                    Limpar
                  </v-btn>
                  <v-btn
                    class="ml-4"
                    color="primary"
                    outlined
                    type="submit"
                    :disabled="buscandoXls"
                    :loading="buscandoXls"
                    @click="exportarTitulosXls()"
                  >
                    Exportar Arquivo xls
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>

    <v-card class="mt-2">
      <v-container fluid>
        <v-data-table
          :headers="headersTitulo"
          :items="titulos"
          :loading="buscando"
          loading-text="Carregando os títulos..."
          :footer-props="{ itemsPerPageOptions: [5, 10, 15, 30, 50], showFirstLastPage: true }"
          :options.sync="optionsTitulos"
          :server-items-length="qtdeTotalTitulos"
        >
          <template #top>
            <v-toolbar-title> Títulos </v-toolbar-title>
          </template>

          <template #[`body.append`]="{ headers }">
            <tr>
              <td :colspan="headers.length">
                <div class="d-flex justify-space-around">
                  <strong>
                    Total Títulos:
                    {{
                      sumValorTitulo.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                      })
                    }}
                  </strong>
                </div>
              </td>
            </tr>
          </template>

          <template #[`item.dataEmissaoTitulo`]="{ item }">
            {{ formatDate(item.dataEmissaoTitulo) }}
          </template>

          <template #[`item.dataVencimentoTitulo`]="{ item }">
            {{ formatDate(item.dataVencimentoTitulo) }}
          </template>

          <template #[`item.valorTitulo`]="{ item }">
            {{ item.valorTitulo.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}
          </template>
          <template #[`item.acoes`]="{ item }">
            <div class="flex-nowrap justify-center">
              <v-tooltip bottom disabled>
                <template v-if="!item.estaConciliado && permiteEditar" #activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    small
                    class="mr-2"
                    v-on="on"
                    @click="() => abrirDialogoDeEdicao(item)"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Editar</span>
              </v-tooltip>
              <v-tooltip bottom disabled>
                <template v-if="!item.estaConciliado && permiteApagar" #activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    small
                    class="mr-2"
                    v-on="on"
                    @click="
                      () => {
                        $refs.dialogoDeConfirmacao.perguntar({
                          onConfirmarCallback: () => apagarTitulo(item),
                          mensagem: `Tem certeza que deseja excluir o título: ${item.codigoTitulo}/${item.parcelaTitulo}?`
                        })
                      }
                    "
                  >
                    mdi-trash-can
                  </v-icon>
                </template>
                <span>Confirmar</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-container>
    </v-card>
    <SnackbarMessage
      :show-snack.sync="snackbar.showSnackParam"
      :snack-text="snackbar.snackTextParam"
      :snack-type="snackbar.snackTypeParam"
    />
    <DialogoDeEdicaoDeTitulo
      ref="dialogoDeEdicao"
      :snackbar="snackbar"
      @salvar="atualizarTituloEditado"
    />
    <DialogoDeConfirmacao ref="dialogoDeConfirmacao" titulo="Excluir" />
  </v-container>
</template>

<script>
import TituloService from '@/services/titulo/titulo'
import EmpresaService from '@/services/organizacao/empresa'
import Snackbar from '@/models/ui/snackbar'
import SnackbarMessage from '@/components/ui/SnackbarMessage'
import { formatDate, formatDateTime, formataCpfCnpj } from '@/shareds/formatacoes'
import OrganizacaoService from '@/services/organizacao/organizacao'
import DialogoDeEdicaoDeTitulo from './DialogoDeEdicaoDeTitulo.vue'
import DialogoDeConfirmacao from '@/components/ui/DialogoDeConfirmacao.vue'
import store from '@/store'
import JSZip from 'jszip'

export default {
  components: {
    SnackbarMessage,
    DialogoDeEdicaoDeTitulo,
    DialogoDeConfirmacao
  },

  data: () => ({
    titulos: [],
    sumValorTitulo: 0,
    headersTitulo: [
      { text: 'Adquirente', value: 'adquirente' },
      { text: 'Codigo Autorização', value: 'codigoAutorizacaoAdquirente' },
      { text: 'Empresa', value: 'empresa.nome' },
      { text: 'Data Emissão', value: 'dataEmissaoTitulo' },
      { text: 'Data Vencimento', value: 'dataVencimentoTitulo' },
      { text: 'Código Título', value: 'codigoTitulo' },
      { text: 'Parcela Título', value: 'parcelaTitulo' },
      { text: 'Valor Título', value: 'valorTitulo' },
      { text: 'Ações', value: 'acoes', sortable: false }
    ],

    itensAdquirente: [
      { text: 'PayPal', value: 'PAYPAL' },
      { text: 'PagSeguro', value: 'PAGSEGURO' },
      { text: 'GetNet', value: 'GETNET' },
      { text: 'Rede', value: 'REDE' }
    ],
    selecaoTitulo: [
      { text: 'Ambos', value: null },
      { text: 'Títulos Editáveis', value: 'false' },
      { text: 'Títulos Não Editáveis', value: 'true' }
    ],
    itensEmpresa: [],
    formatDateTime: formatDateTime,
    formatDate: formatDate,
    snackbar: new Snackbar(),
    buscando: true,
    buscandoXls: false,
    filtro: {
      empresasSelecionadas: []
    },
    menu: false,
    menuDataVencimentoInicial: false,
    menuDataVencimentoFinal: false,
    menuDataEmissaoInicial: false,
    menuDataEmissaoFinal: false,

    dataVencimentoInicialFormatada: '',
    dataVencimentoFinalFormatada: '',
    dataEmissaoInicialFormatada: '',
    dataEmissaoFinalFormatada: '',

    filtroDataVencimentoInicial: '',
    filtroDataVencimentoFinal: '',
    filtroDataEmissaoInicial: '',
    filtroDataEmissaoFinal: '',
    optionsTitulos: {
      page: 1,
      itensPerPage: 0,
      sortBy: []
    },
    qtdeTotalTitulos: 0,
    exportarTitulos: []
  }),

  computed: {
    todasEmpresasSelecionadas: {
      get() {
        return this.filtro.empresasSelecionadas.length === this.itensEmpresa.length
      },
      set(value) {
        return value
      }
    },

    permiteEditar() {
      return store.getters.verificaPermissaoAcesso('Titulo', 'EDITAR')
    },

    permiteApagar() {
      return store.getters.verificaPermissaoAcesso('Titulo', 'REMOVER')
    }
  },

  watch: {
    filtroDataVencimentoInicial() {
      this.dataVencimentoInicialFormatada = formatDate(this.filtroDataVencimentoInicial)
    },

    filtroDataVencimentoFinal() {
      this.dataVencimentoFinalFormatada = formatDate(this.filtroDataVencimentoFinal)
    },

    filtroDataEmissaoInicial() {
      this.dataEmissaoInicialFormatada = formatDate(this.filtroDataEmissaoInicial)
    },

    filtroDataEmissaoFinal() {
      this.dataEmissaoFinalFormatada = formatDate(this.filtroDataEmissaoFinal)
    },

    optionsTitulos: {
      handler() {
        if (this.filtro.adquirente) {
          this.buscarTitulos()
        }
      },

      deep: true
    }
  },

  created() {
    this.initialize()
  },

  methods: {
    async initialize() {
      await this.buscaAdquirentePadraoPelaOrganizacao()
      this.buscarItensEmpresa(this.filtro.adquirente)
      this.buscarTitulos()
    },

    abrirDialogoDeEdicao(item) {
      this.$refs.dialogoDeEdicao.abrir({ ...item })
    },

    atualizarTituloEditado(titulo) {
      const titulos = this.titulos.filter(({ id }) => titulo.id != id)
      this.titulos = [...titulos, titulo]
    },

    alertSuccess(message) {
      this.snackbar.snackTextParam = message
      this.snackbar.snackTypeParam = 'sucess'
      this.snackbar.showSnackParam = true
    },

    async apagarTitulo(item) {
      try {
        await TituloService.excluirTitulo(item.id)
        this.titulos = this.titulos.filter(({ id }) => item.id != id)
        this.alertSuccess('Título apagado com sucesso')
      } catch (erro) {
        this.snackbar.snackTextParam = `${erro.response.status} | ${
          erro.response.data.message === undefined
            ? erro.message
            : erro.response.data.message
            ? erro.response.data.message
            : 'Ocorreu um erro ao apagar título'
        }`
        this.snackbar.snackTypeParam = 'error'
        this.snackbar.showSnackParam = true
      }
    },

    aplicarFiltrosTitulos() {
      Object.assign(this.optionsTitulos, {
        page: 1
      })
      if (this.filtro.valorTitulo?.includes(' ')) {
        this.snackbar.snackTextParam =
          'Valor título informado possui o formato inválido! (Use > ou < ' +
          ' para valores maiores/menores com o valor de título sem utilizar espaços. (Ex: >149.99))'
        this.snackbar.snackTypeParam = 'error'
        this.snackbar.showSnackParam = true
      } else {
        this.buscarTitulos()
      }
    },

    async buscarTitulos() {
      this.buscando = true

      try {
        const paginacao = {
          page: this.optionsTitulos.page - 1,
          size: this.optionsTitulos.itemsPerPage,
          sort: this.optionsTitulos.sortBy.map(
            (sort, indice) => `${sort},${this.optionsTitulos.sortDesc[indice] ? 'DESC' : 'ASC'}`
          )[0]
        }

        const filtros = {
          adquirente: this.filtro.adquirente || undefined,
          codigoTitulo: this.filtro.codigoTitulo || undefined,
          valorTitulo: this.filtro.valorTitulo
            ? this.filtro.valorTitulo.replaceAll('.', '').replaceAll(',', '.')
            : undefined,
          codigoAutorizacaoAdquirente: this.filtro.codigoAutorizacaoAdquirente || undefined,
          empresasIds:
            this.filtro.empresasSelecionadas.length > 0
              ? this.filtro.empresasSelecionadas.join(',')
              : undefined,
          dataInicialVencimentoTitulo: this.filtroDataVencimentoInicial || undefined,
          dataFinalVencimentoTitulo: this.filtroDataVencimentoFinal || undefined,
          dataInicialEmissaoTitulo: this.filtroDataEmissaoInicial || undefined,
          dataFinalEmissaoTitulo: this.filtroDataEmissaoFinal || undefined,
          conciliados: this.filtro.selecaoTitulo || undefined
        }

        const response = await TituloService.buscarTitulosCompletos({ ...filtros, ...paginacao })

        this.titulos = response.content
        this.qtdeTotalTitulos = response.totalElements

        this.buscarValorTotal(filtros)
      } catch (erro) {
        this.snackbar.snackTextParam = `${erro.response.status} | ${
          erro.response.data.message === undefined
            ? erro.message
            : erro.response.data.message
            ? erro.response.data.message
            : 'Ocorreu um erro ao buscar os títulos'
        }`
        this.snackbar.snackTypeParam = 'error'
        this.snackbar.showSnackParam = true
      } finally {
        this.buscando = false
      }
    },

    buscarValorTotal(filtros) {
      TituloService.buscarValorTotal(filtros)
        .then((response) => {
          this.sumValorTitulo = response
        })
        .catch((erro) => {
          this.snackbar.snackTextParam = `${erro.response.status} | ${
            erro.response.data.message === undefined
              ? erro.message
              : erro.response.data.message
              ? erro.response.data.message
              : 'Ocorreu um erro ao buscar o valor total dos títulos.'
          }`
          this.snackbar.snackTypeParam = 'error'
          this.snackbar.showSnackParam = true
        })
        .finally(() => (this.buscandoValores = false))
    },

    async limparSelecaoDeFiltros() {
      this.filtro = {
        adquirente: this.itensAdquirente[0].value,
        empresasSelecionadas: [],
        selecaoTitulo: []
      }
      await this.buscaAdquirentePadraoPelaOrganizacao()

      this.filtroDataVencimentoInicial = ''
      this.filtroDataVencimentoFinal = ''
      this.filtroDataEmissaoInicial = ''
      this.filtroDataEmissaoFinal = ''
      this.optionsTitulos = {
        page: 1,
        sortBy: [],
        itensPerPage: 0
      }
      this.changeValueAdquirente(this.filtro.adquirente)
      this.buscarTitulos()
    },

    changeValueAdquirente(adquirente) {
      this.buscarItensEmpresa(adquirente)
    },

    buscarItensEmpresa(adquirente) {
      this.itensEmpresa = []
      this.filtro.empresasSelecionadas = []

      EmpresaService.buscarTudo({ adquirente })
        .then((response) => {
          this.itensEmpresa = response.map((item) => ({
            text: `${formataCpfCnpj(item.documento)} - ${item.nome}`,
            value: item.id
          }))
        })
        .catch((erro) => {
          this.snackbar.snackTextParam = `${erro.response.status} | ${
            erro.response.data.message === undefined
              ? erro.message
              : erro.response.data.message
              ? erro.response.data.message
              : 'Ocorreu um erro ao buscar os empresas.'
          }`
          this.snackbar.snackTypeParam = 'error'
          this.snackbar.showSnackParam = true
        })
        .finally(() => (this.buscandoEmpresa = false))
    },

    alterarSelecaoEmpresas() {
      this.$nextTick(() => {
        if (this.todasEmpresasSelecionadas) {
          this.filtro.empresasSelecionadas = []
        } else {
          this.filtro.empresasSelecionadas = this.itensEmpresa.slice().map((item) => item.value)
        }
      })
    },

    async buscaAdquirentePadraoPelaOrganizacao() {
      await OrganizacaoService.buscaAdquirentePadrao()
        .then((response) => {
          this.filtro.adquirente = response
        })
        .catch((erro) => {
          this.snackbar.snackTextParam = `${erro.response.status} | ${
            erro.response.data.message === undefined
              ? erro.message
              : erro.response.data.message
              ? erro.response.data.message
              : 'Ocorreu um erro ao buscar o adquirente.'
          }`
          this.snackbar.snackTypeParam = 'error'
          this.snackbar.showSnackParam = true
        })
    },

    exportarTitulosXls() {
      this.buscandoXls = true
      const filtroParams = {
        adquirente: this.filtro.adquirente || undefined,
        codigoTitulo: this.filtro.codigoTitulo || undefined,
        valorTitulo: this.filtro.valorTitulo
          ? this.filtro.valorTitulo.replaceAll('.', '').replaceAll(',', '.')
          : undefined,
        codigoAutorizacaoAdquirente: this.filtro.codigoAutorizacaoAdquirente || undefined,
        empresasIds:
          this.filtro.empresasSelecionadas.length > 0
            ? this.filtro.empresasSelecionadas.join(',')
            : undefined,
        dataInicialVencimentoTitulo: this.filtroDataVencimentoInicial || undefined,
        dataFinalVencimentoTitulo: this.filtroDataVencimentoFinal || undefined,
        dataInicialEmissaoTitulo: this.filtroDataEmissaoInicial || undefined,
        dataFinalEmissaoTitulo: this.filtroDataEmissaoFinal || undefined,
        conciliados: this.filtro.selecaoTitulo || undefined
      }

      TituloService.exportarTitulos(filtroParams)
        .then((response) => {
          this.exportarTitulo = response
          this.downloadzip()
        })
        .catch((erro) => {
          this.snackbar.snackTextParam = `${erro.response.status} | ${
            erro.response.data.message === undefined
              ? erro.message
              : erro.response.data.message
              ? erro.response.data.message
              : 'Ocorreu um erro ao buscar os titulos.'
          }`
          this.snackbar.snackTypeParam = 'error'
          this.snackbar.showSnackParam = true
        })
        .finally(() => (this.buscandoValores = false))
    },

    downloadzip() {
      let zip = new JSZip()
      zip.file('titulos' + '.xls', this.exportarTitulo)

      zip
        .generateAsync({
          type: 'base64'
        })
        .then(function (content) {
          var link = document.createElement('a')
          link.href = 'data:application/zip;base64,' + content
          link.download = 'titulos.zip'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
        .finally(() => (this.buscandoXls = false))
    }
  }
}
</script>

<style scoped>
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: calc(0.4rem + 1vmin);
}
</style>
